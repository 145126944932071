const useQuickCash = () => {
	const { t } = useT();
	const { data: lightCashData, refresh } = useAsyncFetch({
		path: "/rest/cash/input/light-cash/",
		method: "get",
		options: {
			cached: true,
			transform: (data) => {
				const presetsSet = new Set(data.groupPackages.map(Number));
				const filteredPackages = data.packages
					.filter((offer) => presetsSet.has(offer?.money))
					.sort((a, b) => b.money - a.money);

				return {
					...data,
					packages: filteredPackages
				};
			}
		}
	});

	const selectedPackageIndex = ref(0);
	const selectedItem = computed(() => lightCashData.value?.packages[selectedPackageIndex.value]);

	const badgeFullName = computed(() => {
		const label = parseJSON(selectedItem.value.promoOfferPreset?.badgeLabel || "{}");
		return `${
			selectedItem.value.promoOfferPreset?.bestDeal ? t("Best deal") : t("most popular")
		} ${label?.percent} ${label?.text}`;
	});

	const handleOpenCashClick = () => {
		const quickCashQueryParam = lightCashData.value.lastPayProvider?.method_id ? "?quick-cash=true" : "";
		const url = selectedItem.value.promoOfferPreset
			? `/cash/deposit-by-money/${selectedItem.value.promoOfferPreset?.id}/promoOffer/${quickCashQueryParam}`
			: `/cash/deposit-by-money/${selectedItem.value.preset?.id}/preset/${quickCashQueryParam}`;

		window?.$cash?.$router?.push?.(url);
		window?.$store?.gaCash?.deposit({
			location: "fast_deposit",
			type:
				selectedItem.value.relatedUpSaleId || selectedItem.value.promoOfferPreset?.relatedPresetIds?.length
					? "upsale"
					: "",
			step: "view_payments_info"
		});
		dispatchGAEvent({ event: "click_button", button_name: "deposit", location: "fast_deposit" });
	};

	useEvent(["DEPOSIT_COMPLETE"], () => {
		setTimeout(() => refresh(), 1000);
	});

	return {
		lightCashData,
		selectedItem,
		selectedPackageIndex,
		badgeFullName,
		handleOpenCashClick
	};
};
export default useQuickCash;
